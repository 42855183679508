<template>
    <!--    项目管理界面-->
    <div class="project-management">
        <div class="header">
            <!-- <div class="header-nav"  :class="{'bg':pathArr.length>1}"> -->
            <div class="header-nav"  :class="{'bg':pathArr.length>1&&pathArr[2]!='项目概览'}">
                <div class="header-breadcrumb">
                    <el-breadcrumb separator="/">
                        <el-breadcrumb-item v-for="(item, index) in pathArr" :key="index">{{item}}
                        </el-breadcrumb-item>
                    </el-breadcrumb>
                    <el-button type="text" v-if="pathArr[2]=='项目概览'" @click="back()">返回上级</el-button>
                </div>
            </div>
        </div>
        
        <div class="prject-content">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import {getStore, setStore} from "@/util/store";
import {mapMutations, mapState} from "vuex";

export default {
    name: "projectManagement",
    data() {
        return {};
    },
    computed: mapState({
        pathArr: (state) => state.path, //获取导航
        navLevel: (state) => state.pathLevel //获取导航层级
    }),
    created() {

    },
    mounted() {
        var currentMenu = getStore("currentMenu");//先取缓存里的页面路由
        if (currentMenu) {
            this.$router.push({path: currentMenu});
        } else {
            this.$router.push({path: "/projectSearch"});
            this.addMenuList("/projectSearch");//加到历史菜单里，返回上一级时使用
            setStore("currentMenu", "/projectSearch");//当前页录入存入缓存，刷新显示当页
        }
       
    },
    destroyed () {
        //取消监听返回
    },
    methods: {
        ...mapMutations(["reducePath", "addMenuList", "reduceMenuList"]),
        back() {
            this.reduceMenuList();//删除历史菜单中最后一个元素（当前页的路由）
            if(this.pathArr[3]=="项目地图"){
                this.reducePath();//删除导航最后一个路由
            }else{
                this.reducePath();//删除导航最后一个路由
                this.reducePath();//删除导航最后一个路由
            }
            var currentMenu = getStore("currentMenu");
            this.$router.push({path: currentMenu});
        },
    },
};
</script>

<style scoped lang="less">
.project-management {
    height: 100%;
    display: flex;
    flex-direction: column;
    .header{
        height: 52px;
    }
    .header-nav{
        width: 100%;
    }
    .bg{
        background-color: #fff;
    }
    .header-breadcrumb {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
    }

    .prject-content {
        height: calc(100% - 52px);
    }
}
</style>
